// trains_common_queryCityWithSiteInfo 根据城市或者站点获取对应城市的所有站点数据

const __request = require(`@/lib/data-service/trains/__request/__request_contentType_json`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/trains/base-controller/queryCityWithSiteInfoUsingPOST_1
export default function trains_common_queryCityWithSiteInfo(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  const stationCode = pParameter.stationCode || ''
  params.method = 'POST'
  params.urlSuffix = '/trains/common/queryCityWithSiteInfo'
  params.data = {
    stationCode
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
