// trains_product_checkTheRemainingTrainTickets 车次余票查询

const __request = require(`@/lib/data-service/trains/__request/__request_contentType_json`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/trains/product-controller/checkTheRemainingTrainTicketsUsingPOST_1
export default function trains_product_checkTheRemainingTrainTickets(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  const fromStationCode = pParameter.fromStationCode || ''
  const toStationCode = pParameter.toStationCode || ''
  const trainDate = pParameter.trainDate || ''
  params.method = 'POST'
  params.urlSuffix = '/trains/product/checkTheRemainingTrainTickets'
  params.data = {
    fromStationCode,
    toStationCode,
    trainDate
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
