import Calendar from "./../components/calendar";
import trains_common_getTrainEnum from '@/lib/data-service/trains/trains_common_getTrainEnum'
import trains_common_queryCityWithSiteInfo from '@/lib/data-service/trains/trains_common_queryCityWithSiteInfo'
import trains_product_checkTheRemainingTrainTickets from '@/lib/data-service/trains/trains_product_checkTheRemainingTrainTickets'
import trains_common_queryRailwayTimetable from '@/lib/data-service/trains/trains_common_queryRailwayTimetable'
import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/train-city-selector/1.1.0/index.vue'
import Q from 'q'
import moment from 'moment'
export default {
  data () {
    return {
      initStatus: 0,
      loadingNum: 0,
      pickerOptions: {
        disabledDate: this.disabledDate
      },
      moreSearch: false,
      seatTypeList: [], // 坐席
      trainTimeTypeList: [], // 时间段
      trainTypeList: [], // 火车类型
      startStationList: [],
      endStationList: [],
      fromStation: {},
      toStation: {},
      oriFromStation: {},
      oriToStation: {},
      params: {
        fromStationCode: '', // 编号
        toStationCode: '',
        trainDate: ''
      },
      pickerTrainDate: '',
      oriTrainDate: '',
      isAllTrain: true,
      checkTrainList: [],
      isAllSeat: true,
      checkSeatList: [],
      isAllFormTime: true,
      checkFormTrainTimeList: [],
      isAllToTime: true,
      checkToTrainTimeList: [],
      isAllFormStation: true,
      checkFormStationList: [],
      isAllToStation: true,
      checkToStationList: [],
      oriTrainList: [], // 原始数据
      trainList: [], // 结果
      isHasTicket: false, // 是否只显示有票
      formStationTimeSort: false, // 发车时间排序
      formStationTimeSortType: 1, // 1：正序，2：反序
      timeSpentSort: false, // 耗时排序
      timeSpentSortType: 1,
      priceSort: false, // 价格排序
      priceSortType: 1,
      reloadTicketFun: '',
      reloadNum: 0,

      showLoading: false,

      fromStationNum: 0,
      toStationNum: 0,
      daySpacing: '',
    }
  },
  components: {
    Calendar,
    TrainCitySelector
  },
  created () {
    document.documentElement.scrollTop = 0;
  },
  mounted () {
    this.fromStation = {
      stationCode: this.$route.query.fromStationCode
    }
    this.toStation = {
      stationCode: this.$route.query.toStationCode
    }
    this.params.fromStationCode = this.$route.query.fromStationCode
    this.params.toStationCode = this.$route.query.toStationCode
    this.params.trainDate = this.$route.query.time
    this.oriFromStation = Object.assign({}, this.fromStation)
    this.oriToStation = Object.assign({}, this.toStation)
    this.oriTrainDate = this.params.trainDate
    this.pickerTrainDate = this.$route.query.time

    let onlySearch = parseInt(this.$route.query.onlySearch) // 是否只搜动车
    if (onlySearch === 1) {
      this.isAllTrain = false
      this.checkTrainList = [1, 2] // 传入动车的数值
    }
    this.getTrainNum()
    // this.getStationCity(this.params.fromStationCode, 1)
    // this.getStationCity(this.params.toStationCode, 2)
    this.getTrainList()
    this.reloadTicket()
    /*let startDate = moment()
    let endDate = moment('2020-4-29')
    let aa = endDate.diff(startDate, 'days')
    console.log('aa', aa)*/
  },
  activated () {
  },
  deactivated() {},
  destroyed () {
    clearInterval(this.reloadTicketFun)
  },
  watch: {
    fromStation(newVal, oldVal) {
      if (newVal === null) {
        this.params.fromStationCode = ''
      } else {
        this.params.fromStationCode = newVal.stationCode
      }
      if (this.fromStationNum === 0 && newVal.stationCode !== undefined && newVal.stationName !== undefined) {
        this.oriFromStation = Object.assign({}, newVal)
        this.fromStationNum++
      }
    },
    toStation(newVal, oldVal) {
      if (newVal === null) {
        this.params.toStationCode = ''
      } else {
        this.params.toStationCode = newVal.stationCode
      }
      if (this.toStationNum === 0 && newVal.stationCode !== undefined && newVal.stationName !== undefined) {
        this.oriToStation = Object.assign({}, newVal)
        this.toStationNum++
      }
    },
    'params.trainDate'(newVal) {

    }
  },
  computed: {},
  filters: {
    weekFilter(val) {
      let weekNum = parseInt(moment(val).format('d'))
      let week = ''
      switch (weekNum) {
        case 0:
          week = '星期天'
          break;
        case 1:
          week = '星期一'
          break;
        case 2:
          week = '星期二'
          break;
        case 3:
          week = '星期三'
          break;
        case 4:
          week = '星期四'
          break;
        case 5:
          week = '星期五'
          break;
        case 6:
          week = '星期六'
      }
      return week
    }
  },
  methods: {
    toHome() {
      this.$router.push({
        name: 'distributor-train-front-home'
      })
    },
    disabledDate(time) {
      let month = moment().month()
      let day = 0
      if (month === 0 || month === 1) {
        day = 61
      } else if (month === 6) {
        day = 64
      } else {
        day = 63
      }
      return (time.getTime() < Date.now() - 8.64e7) || (time.getTime() > (Date.now() - 8.64e7 + day * 24 * 60 * 60 * 1000))
    },
    openOrClose() {
      let moreSearch = this.moreSearch
      this.moreSearch = !moreSearch
    },
    clearSearchOpa() {
      this.isAllTrain = true
      this.checkTrainList = []
      this.isAllSeat = true
      this.checkSeatList = []
      this.isAllFormTime = true
      this.checkFormTrainTimeList = []
      this.isAllToTime = true
      this.checkToTrainTimeList = []
      this.isAllFormStation = true
      this.checkFormStationList = []
      this.isAllToStation = true
      this.checkToStationList = []
      this.trainList = this.trainFilter(this.oriTrainList)
    },
    changeFormAndTo() {
      let fromStation = this.toStation
      let toStation = this.fromStation
      let fromStationCode = this.params.toStationCode
      let toStationCode = this.params.fromStationCode
      this.fromStation = fromStation
      this.toStation = toStation
      this.params.fromStationCode = fromStationCode
      this.params.toStationCode = toStationCode
    },
    getTrainNum() {
      Q.when().then(() => {
        return trains_common_getTrainEnum()
      }).then((res) => {
        this.seatTypeList = res.seatTypeResults // 坐席
        this.trainTimeTypeList = res.trainTimeTypeResults
        this.trainTypeList = res.trainTypeResults
      })
    },
    getStationCity(val, type) {
      trains_common_queryCityWithSiteInfo({stationCode: val}).then(res => {
        if (type === 1) {
          this.startStationList = res.siteInfoResults
        } else {
          this.endStationList = res.siteInfoResults
        }
      })
    },
    getTrainList() {
      if (this.params.fromStationCode === '' || this.params.fromStationCode === undefined) {
        this.$message({
          message: '请先选择出发地',
          type: 'warning'
        })
        return
      }
      if (this.params.toStationCode === '' || this.params.toStationCode === undefined) {
        this.$message({
          message: '请先选择目的地',
          type: 'warning'
        })
        return
      }

      if (this.pickerTrainDate === '' || this.pickerTrainDate === null) {
        this.$message({
          message: '请先选择日期',
          type: 'warning'
        })
        return
      }
      this.params.trainDate = this.pickerTrainDate
      if (this.params.trainDate === '' || this.params.trainDate === null) {
        this.$message({
          message: '请先选择日期',
          type: 'warning'
        })
        return
      }
      let loading
      loading = this.$loading({
        lock: true,
        text: this.showLoading ? '页面停留超过10分钟，正在为您重新查询' : '',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      trains_product_checkTheRemainingTrainTickets(this.params).then(res => {

        this.showLoading = false

        this.oriTrainDate = this.params.trainDate
        let resultList = res.resultList
        let startStationList = []
        let endStationList = []
        resultList.forEach(value => {
          value.timeTable = []
          // 收集出发和目的地车站
          let startStationItem = {
            stationCode: value.fromStationCode,
            stationName: value.fromStationName
          }
          if (!JSON.stringify(startStationList).includes(JSON.stringify(startStationItem))) {
            startStationList.push(startStationItem)
          }
          let endStationItem = {
            stationCode: value.toStationCode,
            stationName: value.toStationName
          }
          if (!JSON.stringify(endStationList).includes(JSON.stringify(endStationItem))) {
            endStationList.push(endStationItem)
          }
        })
        this.startStationList = startStationList
        this.endStationList = endStationList
        this.oriTrainList = resultList
        this.trainList = this.trainFilter(this.oriTrainList)
        this.setSort()
        this.oriFromStation = Object.assign({}, this.fromStation)
        this.oriToStation = Object.assign({}, this.toStation)

        // 组装当前页面url
        let href = location.href.split('?')[0]
        let onlySearch = this.isAllTrain ? 0 : 1
        let newUrl = href + '?fromStationCode=' + this.fromStation.stationCode + '&toStationCode=' + this.toStation.stationCode + '&time=' + this.params.trainDate + '&onlySearch=' + onlySearch
        history.replaceState(null, null, newUrl)
        loading.close()
        this.loadingNum++
      }).catch(() => {
        loading.close()
        this.showLoading = false
        this.loadingNum++
      })
    },
    trainFilter(list) {
      let trainList = list
      // 根据时间筛选 如果发车日期在一个月内，则不显示'预约抢'的车次，如果发车日期在一个月外，则不显示'预约和'抢票'
      /*let startDay = moment()
      let searchDay = moment(this.params.trainDate)
      this.daySpacing = searchDay.diff(startDay, 'days')
      let dayTrainItemList = []
      trainList.forEach(value => {
        if ((this.daySpacing + 1) === 29 || ((this.daySpacing + 1) <= 30 && value.isSale === '1') || ((this.daySpacing + 1) >= 30 && value.isSale === '0')) { // 预售期内且开售了 || 预售期外且没有开售
          dayTrainItemList.push(value)
        }
      })
      trainList = dayTrainItemList*/
      /*if (daySpacing <= 30) { // 预售期内

      } else { // 预售期外

      }*/
      // 筛选列车类型
      if (!this.isAllTrain && this.checkTrainList.length > 0) {
        let trainItemList = []
        this.checkTrainList.forEach(value => {
          trainList.forEach(val => {
            if (value === val.motorcycleType) {
              trainItemList.push(val)
            }
          })
        })
        trainList = trainItemList
      }
      // 筛选座位
      if (!this.isAllSeat && this.checkSeatList.length > 0) {
        let trainItemList = []
        this.checkSeatList.forEach(value => {
          trainList.forEach(val => {
            val.trainSeatVoList.forEach(rusult => {
              if (value === rusult.seatCode) {
                trainItemList.push(val)
              }
            })
          })
        })
        trainList = trainItemList
      }
      // 筛选出发时段
      if (!this.isAllFormTime && this.checkFormTrainTimeList.length > 0) {
        let trainItemList = []
        this.checkFormTrainTimeList.forEach(value => {
          trainList.forEach(val => {
            if (value === val.fromTimeCode) {
              trainItemList.push(val)
            }
          })
        })
        trainList = trainItemList
      }
      // 筛选到达时段
      if (!this.isAllToTime && this.checkToTrainTimeList.length > 0) {
        let trainItemList = []
        this.checkToTrainTimeList.forEach(value => {
          trainList.forEach(val => {
            if (value === val.toTimeCode) {
              trainItemList.push(val)
            }
          })
        })
        trainList = trainItemList
      }
      // 筛选出发车站
      if (!this.isAllFormStation && this.checkFormStationList.length > 0) {
        let trainItemList = []
        this.checkFormStationList.forEach(value => {
          trainList.forEach(val => {
            if (value === val.fromStationCode) {
              trainItemList.push(val)
            }
          })
        })
        trainList = trainItemList
      }
      // 筛选到达车站
      if (!this.isAllToStation && this.checkToStationList.length > 0) {
        let trainItemList = []
        this.checkToStationList.forEach(value => {
          trainList.forEach(val => {
            if (value === val.toStationCode) {
              trainItemList.push(val)
            }
          })
        })
        trainList = trainItemList
      }
      // 筛选是否显示有票的
      if ((this.daySpacing + 1 <= 30) && this.isHasTicket) {
        let trainItemList = []
        trainList.forEach(value => {
          let hasTicket = false
          if (!this.isAllSeat && this.checkSeatList.length > 0) { // 如果是有筛选座位情况
            value.trainSeatVoList.forEach(val => {
              this.checkSeatList.forEach(result => {
                if (result === val.seatCode && val.seatInventory > 0) {
                  hasTicket = true
                }
              })
            })
          } else { // 没有筛选座位的情况，只要任意一座有座位的话，就纳入
            value.trainSeatVoList.forEach(val => {
              if (val.seatInventory > 0) {
                hasTicket = true
              }
            })
          }
          if (hasTicket) {
            trainItemList.push(value)
          }
        })
        trainList = trainItemList
      }
      return trainList
    },
    // 排序
    setSort() {
      let trainList = this.trainList

      let timeToMinute = function (val) {
        let timeVal = val.split(':')
        let hour = parseInt(timeVal[0]) * 60
        let minute = parseInt(timeVal[1])
        return hour + minute
      }
      // 按出发时间正序排列
      let formTimeCompare = function (x, y) {
        // let xFromTime = moment(x.fromTime).valueOf()
        // let yFromTime = moment(y.fromTime).valueOf()
        let xFromTime = timeToMinute(x.fromTime)
        let yFromTime = timeToMinute(y.fromTime)
        if (xFromTime < yFromTime) {
          return -1;
        } else if (xFromTime > yFromTime) {
          return 1;
        } else {
          return 0;
        }
      }
      // 按出发时间反序排列
      let formTimeReverseCompare = function (x, y) {
        // let xFromTime = moment(x.fromTime).valueOf()
        // let yFromTime = moment(y.fromTime).valueOf()
        let xFromTime = timeToMinute(x.fromTime)
        let yFromTime = timeToMinute(y.fromTime)
        if (xFromTime < yFromTime) {
          return 1;
        } else if (xFromTime > yFromTime) {
          return -1;
        } else {
          return 0;
        }
      }
      // 按耗时正序排列
      let timeSpentCompare = function (x, y) {
        let xSpanMinute = x.spanMinute
        let ySpanMinute = y.spanMinute
        if (xSpanMinute < ySpanMinute) {
          return -1;
        } else if (xSpanMinute > ySpanMinute) {
          return 1;
        } else {
          return 0;
        }
      }
      // 按耗时反序排列
      let timeSpentReverseCompare = function (x, y) {
        let xSpanMinute = x.spanMinute
        let ySpanMinute = y.spanMinute
        if (xSpanMinute < ySpanMinute) {
          return 1;
        } else if (xSpanMinute > ySpanMinute) {
          return -1;
        } else {
          return 0;
        }
      }
      // 按价格正序排列
      let priceCompare = function (x, y) {
        let xPrice = x.trainSeatVoList[0].seatPrice
        let yPrice = y.trainSeatVoList[0].seatPrice
        if (xPrice < yPrice) {
          return -1;
        } else if (xPrice > yPrice) {
          return 1;
        } else {
          return 0;
        }
      }
      // 按价格反序排序
      let priceReverseCompare = function (x, y) {
        let xPrice = x.trainSeatVoList[0].seatPrice
        let yPrice = y.trainSeatVoList[0].seatPrice
        if (xPrice < yPrice) {
          return 1;
        } else if (xPrice > yPrice) {
          return -1;
        } else {
          return 0;
        }
      }

      if (this.formStationTimeSort) {
        if (this.formStationTimeSortType === 1) {
          trainList.sort(formTimeCompare)
        } else {
          trainList.sort(formTimeReverseCompare)
        }
      } else if (this.timeSpentSort) {
        if (this.timeSpentSortType === 1) {
          trainList.sort(timeSpentCompare)
        } else {
          trainList.sort(timeSpentReverseCompare)
        }
      } else if (this.priceSort) {
        if (this.priceSortType === 1) {
          trainList.sort(priceCompare)
        } else {
          trainList.sort(priceReverseCompare)
        }
      }
      this.trainList = trainList
    },
    // 点击出发时间排序
    changeFormStationTimeSort() {
      if (this.formStationTimeSort) {
        if (this.formStationTimeSortType === 1) {
          this.formStationTimeSortType = 2
        } else {
          this.formStationTimeSortType = 1
        }
      } else {
        this.formStationTimeSort = true
        this.formStationTimeSortType = 1
        this.timeSpentSort = false
        this.priceSort = false
      }
      this.setSort()
    },
    changeTimeSpentSort() {
      if (this.timeSpentSort) {
        if (this.timeSpentSortType === 1) {
          this.timeSpentSortType = 2
        } else {
          this.timeSpentSortType = 1
        }
      } else {
        this.timeSpentSort = true
        this.timeSpentSortType = 1
        this.formStationTimeSort = false
        this.priceSort = false
      }
      this.setSort()
    },
    changePriceSort() {
      if (this.priceSort) {
        if (this.priceSortType === 1) {
          this.priceSortType = 2
        } else {
          this.priceSortType = 1
        }
      } else {
        this.priceSort = true
        this.priceSortType = 1
        this.formStationTimeSort = false
        this.timeSpentSort = false
      }
      this.setSort()
    },
    changeHasTicket(val) {
      this.trainList = this.trainFilter(this.oriTrainList)
    },
    changeTrainConditionAll(type, val) {
      if (!val) {
        return
      }
      if (type === 'train') {
        this.checkTrainList = []
      } else if (type === 'seat') {
        this.checkSeatList = []
      } else if (type === 'formTime') {
        this.checkFormTrainTimeList = []
      } else if (type === 'toTime') {
        this.checkToTrainTimeList = []
      } else if (type === 'formStation') {
        this.checkFormStationList = []
      } else {
        this.checkToStationList = []
      }
      this.trainList = this.trainFilter(this.oriTrainList)
    },
    changeTrainConditionItem(type, val) {
      if (type === 'train') {
        if (val.length === 0) {
          this.isAllTrain = true
        } else {
          this.isAllTrain = false
        }
      } else if (type === 'seat') {
        if (val.length === 0) {
          this.isAllSeat = true
        } else {
          this.isAllSeat = false
        }
      } else if (type === 'formTime') {
        if (val.length === 0) {
          this.isAllFormTime = true
        } else {
          this.isAllFormTime = false
        }
      } else if (type === 'toTime') {
        if (val.length === 0) {
          this.isAllToTime = true
        } else {
          this.isAllToTime = false
        }
      } else if (type === 'formStation') {
        if (val.length === 0) {
          this.isAllFormStation = true
        } else {
          this.isAllFormStation = false
        }
      } else {
        if (val.length === 0) {
          this.isAllToStation = true
        } else {
          this.isAllToStation = false
        }
      }
      this.trainList = this.trainFilter(this.oriTrainList)
    },
    getStationIcon(val) {
      if (val === 1) {
        return 'start-icon'
      } else if (val === 2) {
        return 'pass-by-icon'
      } else {
        return 'end-icon'
      }
    },
    clickDate(val) {
      this.params.trainDate = val
      this.pickerTrainDate = val
      this.getTrainList()
    },
    showThisStationList(index, val) {
      if (val.timeTable.length > 0) {
        return
      }
      let params = {
        fromStationCode: val.fromStationCode,
        toStationCode: val.toStationCode,
        trainCode: val.trainCode,
        trainDate: moment(val.trainStartDate).format('YYYY-MM-DD')
      }
      trains_common_queryRailwayTimetable(params).then(res => {
        let result = res.results
        let listType = 0
        for (let i = 0, l = result.length; i < l; i++) {
          if (result[i].stationName === val.fromStationName) {
            listType = 1
            result[i].listType = 2
            continue
          }
          if (result[i].stationName === val.toStationName) {
            listType = 0
            result[i].listType = 2
            continue
          }
          result[i].listType = listType
        }
        this.trainList[index].timeTable = result
      })
    },
    tableRowClassName({row, rowIndex}) {
      if (row.listType === 0) {
        return 'grey-line'
      } else if (row.listType === 1) {
        return 'default-line'
      } else {
        return 'active-line'
      }
    },
    reserve(val, index, sIndex) {
      this.$message({
        type: "warning",
        message: '系统正在维护...'
      })
      return
      this.$router.push({
        name: 'distributor-train-front-reserve',
        query: {
          expressFee: val.expressFee,
          fromStationCode: val.fromStationCode,
          fromStationName: val.fromStationName,
          fromStationNo: val.fromStationNo,
          // fromStationType: val.fromStationType,
          fromTime: val.fromTime,
          fromTimeCode: val.fromTimeCode,
          motorcycleType: val.motorcycleType,
          motorcycleTypeText: val.motorcycleTypeText,
          serviceCharge: val.serviceCharge,
          spanMinute: val.spanMinute,
          spanTime: val.spanTime,
          toStationCode: val.toStationCode,
          toStationName: val.toStationName,
          toStationNo: val.toStationNo,
          // toStationType: val.toStationType,
          toTime: val.toTime,
          toTimeCode: val.toTimeCode,
          trainCode: val.trainCode,
          trainNo: val.trainNo,
          trainStartDate: val.trainStartDate,
          isSale: val.isSale,
          arriveDate: val.arriveDate,

          fromDate: this.params.trainDate,

          canChooseSeat: val.trainSeatVoList[sIndex].canChooseSeat ? 1 : 0,
          numberOfSeats: val.trainSeatVoList[sIndex].numberOfSeats,
          seatCode: val.trainSeatVoList[sIndex].seatCode,
          seatInventory: val.trainSeatVoList[sIndex].seatInventory,
          seatName: val.trainSeatVoList[sIndex].seatName,
          seatPrice: val.trainSeatVoList[sIndex].seatPrice,
          trainSeatFlag: val.trainSeatVoList[sIndex].trainSeatFlag,

          searchFormStationCode: this.oriFromStation.stationCode,
          searchToStationCode: this.oriToStation.stationCode
        }
      })
    },
    ticketGrabbing(val, index, sIndex) {
      this.$message({
        type: "warning",
        message: '系统正在维护...'
      })
      return
      this.$router.push({
        name: 'distributor-train-front-booking',
        query: {
          expressFee: val.expressFee,
          fromStationCode: val.fromStationCode,
          fromStationName: val.fromStationName,
          fromStationNo: val.fromStationNo,
          // fromStationType: val.fromStationType,
          fromTime: val.fromTime,
          fromTimeCode: val.fromTimeCode,
          motorcycleType: val.motorcycleType,
          motorcycleTypeText: val.motorcycleTypeText,
          serviceCharge: val.serviceCharge,
          spanMinute: val.spanMinute,
          spanTime: val.spanTime,
          toStationCode: val.toStationCode,
          toStationName: val.toStationName,
          toStationNo: val.toStationNo,
          // toStationType: val.toStationType,
          toTime: val.toTime,
          toTimeCode: val.toTimeCode,
          trainCode: val.trainCode,
          trainNo: val.trainNo,
          trainStartDate: val.trainStartDate,
          arriveDate: val.arriveDate,
          isSale: val.isSale,

          fromDate: this.params.trainDate,

          canChooseSeat: val.trainSeatVoList[sIndex].canChooseSeat ? 1 : 0,
          // numberOfSeats: val.trainSeatVoList[index].numberOfSeats, // 在线选座每排座位数
          seatCode: val.trainSeatVoList[sIndex].seatCode,
          seatInventory: val.trainSeatVoList[sIndex].seatInventory,
          seatName: val.trainSeatVoList[sIndex].seatName,
          seatPrice: val.trainSeatVoList[sIndex].seatPrice,
          trainSeatFlag: val.trainSeatVoList[sIndex].trainSeatFlag,

          searchFormStationCode: this.oriFromStation.stationCode,
          searchToStationCode: this.oriToStation.stationCode,

          seatList: JSON.stringify(val.trainSeatVoList),
        }
      })
    },
    reloadTicket() {
      this.reloadTicketFun = setInterval(() => {
        this.reloadNum++
        if (this.reloadNum === 10 * 60) {
          this.showLoading = true
          this.getTrainList()
          // this.$message('页面停留超过10分钟，正在为您重新查询');
          // 页面停留超过10分钟，正在为您重新查询
          this.reloadNum = 0
        }
      }, 1000)
    },
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
